import "./Signup.css";

import { API, Auth } from "aws-amplify";
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import LoaderButton from "../components/LoaderButton";
import Loading from "./Loading";
import NumberFormat from "react-number-format";
import ReactPixel from "react-facebook-pixel";
import { useFormFields } from "../libs/hooksLib";

export default function Signup(props) {
  const [fields, handleFieldChange] = useFormFields({
    password: "adminPassword",
    confirmPassword: "adminPassword",
  });
  const [retirementAge, setRetirementAge] = useState("65");
  const [questionName, setQuestionName] = useState("retirementAge");
  const [progress, setProgress] = useState("10%");
  const [hasSpouse, setHasSpouse] = useState("no");
  const [currentAge, setCurrentAge] = useState("");
  const [kidCount, setKidCount] = useState("0");
  const [householdIncome, setHouseholdIncome] = useState("");
  const [hasBenefit, setHasBenefit] = useState("no");
  const [invested, setInvested] = useState("");
  const [monthlySavings, setMonthlySavings] = useState("");
  const [rentOwn, setRentOwn] = useState("rent");
  const [hasDebt, setHasDebt] = useState("no");
  const [firstName, setFirstName] = useState("");
  const [riskTolerence, setRiskTolerence] = useState("none");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [picIndex, setPicIndex] = useState(3);
  const [code, setCode] = useState(Math.floor(1000 + Math.random() * 9000));
  const [verification, setVerification] = useState(null);

  useEffect(() => {
    ReactPixel.init("169369847817130");
    switch (questionName) {
      case "retirementAge":
        setPicIndex(1);
        setProgress("5%");
        break;
      case "hasSpouse":
        ReactPixel.trackCustom("retirementAge", { retirementAge });
        setPicIndex(2);
        setProgress("15%");
        break;
      case "currentAge":
        setPicIndex(3);
        setProgress("25%");
        break;
      case "kidCount":
        setPicIndex(4);
        setProgress("35%");
        break;
      case "householdIncome":
        setPicIndex(5);
        setProgress("45%");
        break;
      case "hasBenefit":
        setPicIndex(6);
        setProgress("55%");
        break;
      case "firstName":
        setPicIndex(7);
        setProgress("60%");
        break;
      case "invested":
        setPicIndex(8);
        setProgress("65%");
        break;
      case "monthlySavings":
        setPicIndex(9);
        setProgress("70%");
        break;
      case "rentOwn":
        setPicIndex(10);
        setProgress("75%");
        break;
      case "hasDebt":
        setPicIndex(1);
        setProgress("80%");
        break;
      case "riskTolerence":
        setPicIndex(2);
        setProgress("86%");
        break;
      case "postalCode":
        setPicIndex(3);
        setProgress("90%");
        break;
      case "phone":
        setPicIndex(5);
        setProgress("95%");
        break;
      case "verifyNumber":
        setPicIndex(7);
        setProgress("97%");
        break;
      case "accountForm":
        setPicIndex(6);
        setProgress("100%");
        break;
      default:
        return null;
    }
  }, [
    questionName,
    retirementAge,
    hasSpouse,
    currentAge,
    householdIncome,
    invested,
    rentOwn,
    hasDebt,
    kidCount,
    riskTolerence,
    phone,
    firstName,
    hasBenefit,
    monthlySavings,
    postalCode,
    code,
  ]);

  function renderForm() {
    switch (questionName) {
      case "retirementAge":
        return retirementAgeQuestion();
      case "hasSpouse":
        return hasSpouseQuestion();
      case "currentAge":
        return currentAgeQuestion();
      case "kidCount":
        return kidCountQuestion();
      case "householdIncome":
        return householdIncomeQuestion();
      case "hasBenefit":
        return hasBenefitQuestion();
      case "invested":
        return investedQuestion();
      case "monthlySavings":
        return monthlySavingsQuestion();
      case "rentOwn":
        return rentOwnQuestion();
      case "firstName":
        return firstNameQuestion();
      case "hasDebt":
        return hasDebtQuestion();
      case "riskTolerence":
        return riskTolerenceQuestion();
      case "postalCode":
        return postalCodeQuestion();
      case "phone":
        return phoneQuestion();
      case "verifyNumber":
        return verifyNumberQuestion();
      case "accountForm":
        return accountForm();
      default:
        return null;
    }
  }

  function phoneQuestionValidation() {
    return phone.replace("_", "").length === 17;
  }

  function verificationValidation() {
    return code === parseInt(verification);
  }

  function verifyNumberQuestion() {
    return (
      <div>
        <p>Your verification code (it may take up to 30 seconds to receive)</p>
        <NumberFormat
          className="form-control"
          value={verification}
          onChange={handleVerificationChange}
          format="####"
          mask="_"
        />
        <button
          disabled={!verificationValidation()}
          onClick={() => setQuestionName("accountForm")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div onClick={() => setQuestionName("phone")} className="auth__link">
          Back
        </div>
      </div>
    );
  }

  async function validateNumber() {
    setQuestionName("verifyNumber");
    await sendCode();
  }

  function sendCode() {
    setCode(code);
    return API.post("profiles", "/code", {
      body: {
        code,
        phone,
      },
    });
  }

  function phoneQuestion() {
    return (
      <div>
        <p>Mobile Phone number (we will send you a security code)</p>
        <NumberFormat
          className="form-control"
          value={phone}
          onChange={handlePhoneChange}
          format="+1 (###) ###-####"
          mask="_"
        />
        <button
          disabled={!phoneQuestionValidation()}
          onClick={() => validateNumber()}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div
          onClick={() => setQuestionName("postalCode")}
          className="auth__link"
        >
          Back
        </div>
      </div>
    );
  }

  function riskTolerenceQuestion() {
    return (
      <div>
        <p>What's your risk tolerance?</p>
        <p onClick={() => setRiskTolerence("none")}>
          <input
            type="radio"
            checked={riskTolerence === "none" ? "checked" : ""}
          />{" "}
          I prefer little to no ups or downs
        </p>
        <p onClick={() => setRiskTolerence("considerable")}>
          <input
            type="radio"
            checked={riskTolerence === "considerable" ? "checked" : ""}
          />{" "}
          I’m okay with considerable ups and downs
        </p>
        <p onClick={() => setRiskTolerence("moderate")}>
          <input
            type="radio"
            checked={riskTolerence === "moderate" ? "checked" : ""}
          />{" "}
          I’m okay with moderate ups and downs
        </p>
        <p onClick={() => setRiskTolerence("slight")}>
          <input
            type="radio"
            checked={riskTolerence === "slight" ? "checked" : ""}
          />{" "}
          I’m okay with slight ups and downs
        </p>
        <button
          onClick={() => setQuestionName("postalCode")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div onClick={() => setQuestionName("hasDebt")} className="auth__link">
          Back
        </div>
      </div>
    );
  }

  function hasDebtQuestion() {
    return (
      <div>
        <p>Are you currently paying off any debt? (including a mortgage)</p>
        <p onClick={() => setHasDebt("no")}>
          <input type="radio" checked={hasDebt === "no" ? "checked" : ""} /> no
        </p>
        <p onClick={() => setHasDebt("yes")}>
          <input type="radio" checked={hasDebt === "yes" ? "checked" : ""} />{" "}
          yes
        </p>
        <button
          onClick={() => setQuestionName("riskTolerence")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div onClick={() => setQuestionName("rentOwn")} className="auth__link">
          Back
        </div>
      </div>
    );
  }

  function rentOwnQuestion() {
    return (
      <div>
        <p>Do you rent or own your home?</p>
        <p onClick={() => setRentOwn("rent")}>
          <input type="radio" checked={rentOwn === "rent" ? "checked" : ""} />{" "}
          rent
        </p>
        <p onClick={() => setRentOwn("own")}>
          <input type="radio" checked={rentOwn === "own" ? "checked" : ""} />{" "}
          own
        </p>
        <button
          onClick={() => setQuestionName("hasDebt")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div
          onClick={() => setQuestionName("monthlySavings")}
          className="auth__link"
        >
          Back
        </div>
      </div>
    );
  }

  function monthlySavingsValidation() {
    return monthlySavings.length >= 1;
  }

  function monthlySavingsQuestion() {
    return (
      <div>
        <p>How much does your household save monthly?</p>
        <NumberFormat
          className="form-control"
          value={monthlySavings}
          onChange={handleMonthlySavingsChange}
          thousandSeparator={","}
          prefix={"$"}
        />
        <button
          disabled={!monthlySavingsValidation()}
          onClick={() => setQuestionName("rentOwn")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div onClick={() => setQuestionName("invested")} className="auth__link">
          Back
        </div>
      </div>
    );
  }

  function postalCodeValidation() {
    return postalCode.length >= 1;
  }

  function postalCodeQuestion() {
    return (
      <div>
        <p>What is your postal code?</p>
        <FormControl
          className="form-control"
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
        />
        <button
          disabled={!postalCodeValidation()}
          onClick={() => setQuestionName("phone")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div
          onClick={() => setQuestionName("riskTolerence")}
          className="auth__link"
        >
          Back
        </div>
      </div>
    );
  }

  function investedValidation() {
    return invested.length >= 1;
  }

  function investedQuestion() {
    return (
      <div>
        <p>How much do you have invested?</p>
        <NumberFormat
          className="form-control"
          value={invested}
          onChange={handleInvestedChange}
          thousandSeparator={","}
          prefix={"$"}
        />
        <button
          disabled={!investedValidation()}
          onClick={() => setQuestionName("monthlySavings")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div
          onClick={() => setQuestionName("firstName")}
          className="auth__link"
        >
          Back
        </div>
      </div>
    );
  }

  function handleFirstValidation() {
    return firstName.length >= 2;
  }

  function firstNameQuestion() {
    return (
      <div>
        <p>What is your first name?</p>
        <FormControl
          type="text"
          value={firstName}
          onChange={handleFirstNameChange}
        />
        <button
          disabled={!handleFirstValidation()}
          onClick={() => setQuestionName("invested")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div
          onClick={() => setQuestionName("hasBenefit")}
          className="auth__link"
        >
          Back
        </div>
      </div>
    );
  }

  function hasBenefitQuestion() {
    return (
      <div>
        <p>Do you have a defined benefit workplace pension?</p>
        <p onClick={() => setHasBenefit("no")}>
          <input type="radio" checked={hasBenefit === "no" ? "checked" : ""} />{" "}
          no
        </p>
        <p onClick={() => setHasBenefit("yes")}>
          <input type="radio" checked={hasBenefit === "yes" ? "checked" : ""} />{" "}
          yes
        </p>
        <button
          onClick={() => setQuestionName("firstName")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div
          onClick={() => setQuestionName("householdIncome")}
          className="auth__link"
        >
          Back
        </div>
      </div>
    );
  }

  function householdIncomeValidation() {
    return householdIncome.length >= 1;
  }

  function householdIncomeQuestion() {
    return (
      <div>
        <p>What’s your total annual household income?</p>
        <NumberFormat
          className="form-control"
          value={householdIncome}
          onChange={handleHouseholdIncome}
          thousandSeparator={","}
          prefix={"$"}
        />
        <button
          disabled={!householdIncomeValidation()}
          onClick={() => setQuestionName("hasBenefit")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div onClick={() => setQuestionName("kidCount")} className="auth__link">
          Back
        </div>
      </div>
    );
  }

  function kidCountQuestion() {
    return (
      <div>
        <p>How many kids do you have under the age of 26?</p>
        <p onClick={() => setKidCount("0")}>
          <input type="radio" checked={kidCount === "0" ? "checked" : ""} /> 0
        </p>
        <p onClick={() => setKidCount("1")}>
          <input type="radio" checked={kidCount === "1" ? "checked" : ""} /> 1
        </p>
        <p onClick={() => setKidCount("2")}>
          <input type="radio" checked={kidCount === "2" ? "checked" : ""} /> 2
        </p>
        <p onClick={() => setKidCount("3+")}>
          <input type="radio" checked={kidCount === "3+" ? "checked" : ""} /> 3+
        </p>
        <button
          onClick={() => setQuestionName("householdIncome")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div
          onClick={() => setQuestionName("currentAge")}
          className="auth__link"
        >
          Back
        </div>
      </div>
    );
  }

  function retirementAgeQuestion() {
    return (
      <div>
        <p>What's your ideal retirement age?</p>
        <FormControl
          type="text"
          value={retirementAge}
          onChange={handleRetirementAgeChange}
        />
        <button
          onClick={() => setQuestionName("hasSpouse")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function hasSpouseQuestion() {
    return (
      <div>
        <p>Do you want to include your spouse in your plan?</p>
        <p onClick={() => setHasSpouse("no")}>
          <input type="radio" checked={hasSpouse === "no" ? "checked" : ""} />{" "}
          no
        </p>
        <p onClick={() => setHasSpouse("yes")}>
          <input type="radio" checked={hasSpouse === "yes" ? "checked" : ""} />{" "}
          yes
        </p>
        <button
          onClick={() => setQuestionName("currentAge")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div
          onClick={() => setQuestionName("retirementAge")}
          className="auth__link text-left"
        >
          Back
        </div>
      </div>
    );
  }

  function currentAgeValidation() {
    return currentAge.length === 2;
  }

  function currentAgeQuestion() {
    return (
      <div>
        <p>How old are you?</p>
        <FormControl
          type="text"
          value={currentAge}
          onChange={handleCurrentAgeChange}
        />
        <button
          disabled={!currentAgeValidation()}
          onClick={() => setQuestionName("kidCount")}
          className="btn-primary"
        >
          Next
        </button>
        <br />
        <br />
        <br />
        <div
          onClick={() => setQuestionName("hasSpouse")}
          className="auth__link"
        >
          Back
        </div>
      </div>
    );
  }

  function handleEmailChange(evt) {
    setEmail(evt.target.value);
  }

  function handleFirstNameChange(evt) {
    setFirstName(evt.target.value);
  }

  function handleVerificationChange(evt) {
    setVerification(evt.target.value);
  }

  function handlePhoneChange(evt) {
    setPhone(evt.target.value);
  }

  function handleRetirementAgeChange(evt) {
    setRetirementAge(evt.target.value);
  }

  function handleCurrentAgeChange(evt) {
    setCurrentAge(evt.target.value);
  }

  function handleHouseholdIncome(evt) {
    setHouseholdIncome(evt.target.value);
  }

  function handleInvestedChange(evt) {
    setInvested(evt.target.value);
  }

  function handleMonthlySavingsChange(evt) {
    setMonthlySavings(evt.target.value);
  }

  function accountForm() {
    return (
      <form onSubmit={handleSubmit}>
        <p>Email</p>
        <FormControl type="email" value={email} onChange={handleEmailChange} />
        <FormGroup className="hidden" controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup
          className="hidden"
          controlId="confirmPassword"
          bsSize="large"
        >
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </FormGroup>
        <LoaderButton
          className="btn-primary"
          type="submit"
          bsSize="large"
          isLoading={isLoading}
        >
          Continue
        </LoaderButton>
        <br />
        <br />
        <br />
        <div onClick={() => setQuestionName("hasDebt")} className="auth__link">
          Back
        </div>
      </form>
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signUp({
        username: email,
        password: "fields.password",
      });
      await Auth.signIn(email, "fields.password");
      ReactPixel.track("Lead");
      const profile = await createProfile();
      props.history.push(`/p/${profile.profileId}`);
    } catch (e) {
      alert("Email already used, try using a different email.");
      setIsLoading(false);
    }
  }

  function createProfile() {
    return API.post("profiles", "/profiles", {
      body: {
        firstName,
        retirementAge,
        hasSpouse,
        currentAge,
        kidCount,
        householdIncome,
        hasBenefit,
        invested,
        monthlySavings,
        rentOwn,
        hasDebt,
        riskTolerence,
        phone,
        refCode: props.match.params.id,
        email,
      },
    });
  }

  return (
    <div className="Signup container text-center">
      {isLoading ? (
        <Loading />
      ) : (
        <Row>
          <Col sm={8} smOffset={2}>
            <br />
            <div className="form-wrapper">
              <div className="progress-container">
                <div className="progress" style={{ width: progress }}></div>
              </div>
              <br />
              {renderForm()}
              <br />
            </div>
          </Col>
          <Col sm={2}>
            <img
              className="fillerImg"
              alt="saver the good times"
              src={`https://retirement-marketing.s3.amazonaws.com/${picIndex}.png`}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}
