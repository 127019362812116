import "./Terms.css";

import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

export default class Terms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }
  render() {
    return (
      <div className="Terms container">
        <Row>
          <Col sm={12}>
            <h1 className="text-center">Terms of Service</h1>
            <p>1. LICENSE</p>
            <p>1.1. Governing Agreement</p>
            <p>
              The terms of this agreement ("Terms of Service") govern the
              relationship between you and Optimity Inc. with an office at 91
              Oxford St., Toronto, ON M5T 1P2, Canada (hereinafter "Optimity",
              "Us", "We" or "Our") regarding your use of the FINWELL Service.
            </p>
            <p>1.2. Privacy Policy</p>
            <p>
              Use of the Service is also governed by FINWELL's Privacy Policy,
              which is incorporated herein by reference. Your privacy is
              important to Us and Our Privacy Policy explains how we collect and
              use your content and information and how you can use the Service
              to share such information with others. By creating an account or
              accessing or using the Service you accept and agree to be bound by
              these Terms of Service and consent to the collection, use and
              storage of your information as outlined in our Privacy Policy.
            </p>
            <p>1.3. Updates to the Terms of Service and Privacy Policy</p>
            <p>
              We reserve the right, at our discretion, to change, modify, add or
              remove portions of these Terms of Service and its Privacy Policy
              at any time by posting the amended Terms in the FINWELL Service.
              You may also be given additional notice, such as an e-mail message
              or messaging within the Service, of any changes. You will be
              deemed to have accepted such changes by continuing to use any of
              FINWELL's Services. Except as otherwise stated, all amended terms
              shall automatically be effective 30 days after they are initially
              posted. Optimity may also revise other policies, codes or rules at
              any time, and the new versions will be available on FINWELLS
              Services. If at any point you do not agree to any portion of our
              Terms of Service, our Privacy Policy, or any other policy, rule or
              code of conduct relating to your use of the Service, your license
              to use the Service shall immediately terminate, and you must
              immediately stop using the Service. To the extent the Terms of
              Service or Privacy Policy conflict with any other FINWELL or
              Optimity terms, policy, rules or codes of conduct, the terms
              contained in these Terms of Service and in the Privacy Policy
              shall govern.
            </p>
            <p>1.4. Accessing the Service</p>
            <p>
              Before accessing or using the Service, you must agree to these
              Terms of Service and the Privacy Policy. If you are a minor, you
              represent that your legal guardian has reviewed and agreed to
              these Terms on your behalf. You must provide all equipment and
              software necessary to connect to the Service, including, but not
              limited to, a mobile device that is suitable to connect with and
              use the Service, in cases where the Service offers a mobile
              component. You are responsible for any fees, including internet
              connection or mobile fees that you incur when accessing the
              Service.
            </p>
            <p>1.5. Use of the Service</p>
            <p>
              You acknowledge that FINWELL is a voluntary service. Everything
              you see on our site, such as an article or a video or even a link
              to another website, is meant to give you general information. It
              is not meant to be taken as legal, accounting, financial,
              insurance, borrowing or tax advice, and it is not meant as an
              offer to sell you anything. You must create your own financial
              plan and talk with an expert in order to receive advice on your
              personal situation.{" "}
            </p>
            <p>1.6. Account Information</p>
            <p>
              When using the Service, you are required to provide Us with the
              personal information necessary for the use of the Service and this
              information will be held and used in accordance with Our Privacy
              Policy.{" "}
            </p>

            <p>2. OWNERSHIP</p>
            <p>2.1. Service</p>
            <p>
              The Service (including without limitation any graphics, titles,
              computer code, themes, objects, characters, character names,
              stories, dialogue, catch phrases, concepts, artwork, animations,
              sounds, musical compositions, audio-visual effects, methods of
              operation, moral rights, documentation, transcripts, character
              profile information, recordings) are copyrighted works owned by
              Optimity or licensed to Optimity. Optimity reserves all rights,
              including without limitation, all intellectual property rights or
              other proprietary rights, in connection with the Service.
            </p>
            <p>2.2. Accounts</p>
            <p>
              NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, YOU ACKNOWLEDGE
              AND AGREE THAT YOU SHALL HAVE NO OWNERSHIP OR OTHER PROPERTY
              INTEREST IN THE INFORMATION PROVIDED THROUGH THE SERVICE, AND YOU
              FURTHER ACKNOWLEDGE AND AGREE THAT ALL RIGHTS IN AND TO THE
              INFORMATION ARE AND SHALL FOREVER BE OWNED BY AND INURE TO THE
              BENEFIT OF OPTIMITY. WE MAY TERMINATE OR SUSPEND YOUR USE OF THE
              SERVICE IMMEDIATELY, WITHOUT PRIOR NOTICE OR LIABILITY, FOR ANY
              REASON WHATSOEVER, INCLUDING WITHOUT LIMITATION IF YOU BREACH
              THESE TERMS AND CONDITIONS. UPON TERMINATION, YOUR RIGHT TO USE
              THE SERVICE WILL CEASE IMMEDIATELY.{" "}
            </p>
            <p>2.3. User Content</p>
            <p>
              "User Content" means any communications, images, sounds, and all
              the material, data, and information that you upload or transmit
              through any Service, or that other users upload or transmit,
              including without limitation any chat text. By transmitting or
              submitting any User Content while using the Service, you affirm,
              represent and warrant that such transmission or submission is (a)
              accurate and not confidential; (b) not in violation of any laws,
              contractual restrictions or other third party rights, and that you
              have permission from any third party whose personal information or
              intellectual property is comprised in the User Content; (c) free
              of viruses, adware, spyware, worms or other malicious code; and
              (d) you acknowledge and agree that any of your personal
              information within such content will at all times be processed by
              Optimity in accordance with Our Privacy Policy. You hereby grant
              Optimity a perpetual and irrevocable (other than as provided
              below), worldwide, fully paid-up and royalty free, non-exclusive,
              unlimited license, including the right to sublicense and assign to
              third parties, and right to copy, reproduce, fix, adapt, modify,
              improve, translate, reformat, create derivative works from,
              manufacture, introduce into circulation, commercialize, publish,
              distribute, sell, license, sublicense, transfer, rent, lease,
              transmit, publicly display, publicly perform, or provide access to
              electronically, broadcast, communicate to the public by
              telecommunication, display, perform, enter into computer memory,
              and use and practice, in any way now known or in the future
              discovered, your User Content as well as all modified and
              derivative works thereof. To the extent permitted by applicable
              laws, you hereby waive any moral rights you may have in any User
              Content. The license you grant Us to use user posted content ends
              when you delete your User Content or you close your Account unless
              your User content has been shared with others, and they have not
              deleted it. However, you understand and accept that removed
              content may persist in back-up copies for a reasonable period of
              time.
            </p>
            <p>
              You understand that the Service is an evolving one. Optimity may
              require that you accept updates to the Service. You acknowledge
              and agree that Optimity may update the Service with or without
              notifying you. You may need to update third party software from
              time to time in order to continue receiving the Service.
            </p>
            <p>4. DISCLAIMERS / LIMITATIONS / WAIVERS / INDEMNIFICATION</p>
            <p>4.1. Disclaimer of Warranties</p>
            <p>
              YOU EXPRESSLY AGREE THAT USE OF THE SERVICE IS AT YOUR SOLE RISK
              AND IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY
              KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
              WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF NON- INFRINGEMENT,
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE (EXCEPT ONLY
              TO THE EXTENT PROHIBITED UNDER APPLICABLE LAW WITH ANY LEGALLY
              REQUIRED WARRANTY PERIOD THE SHORTER OF THIRTY DAYS FROM FIRST USE
              OR THE MINIMUM PERIOD REQUIRED). WITHOUT LIMITING THE FOREGOING,
              NEITHER OPTIMITY NOR ITS AFFILIATES OR SUBSIDIARIES, OR ANY OF
              THEIR DIRECTORS, EMPLOYEES, AGENTS, ATTORNEYS, THIRD-PARTY CONTENT
              PROVIDERS, DISTRIBUTORS, LICENSEES OR LICENSORS (COLLECTIVELY,
              "OPTIMITY PARTIES") WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED
              OR ERROR-FREE.
            </p>
            <p>4.2. Limitations; Waivers of Liability</p>
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY
              ANY APPLICABLE LAW, THE DISCLAIMERS OF LIABILITY CONTAINED HEREIN
              APPLY TO ANY AND ALL DAMAGES OR INJURY WHATSOEVER CAUSED BY OR
              RELATED TO USE OF, OR INABILITY TO USE, THE SERVICE UNDER ANY
              CAUSE OR ACTION WHATSOEVER OF ANY JURISDICTION, INCLUDING, WITHOUT
              LIMITATION, ACTIONS FOR BREACH OF WARRANTY, BREACH OF CONTRACT OR
              TORT (INCLUDING NEGLIGENCE) AND THAT THE OPTIMITY PARTIES SHALL
              NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR
              CONSEQUENTIAL DAMAGES IN ANY WAY WHATSOEVER ARISING OUT OF THE USE
              OF, OR INABILITY TO USE, THE SERVICE. YOU FURTHER SPECIFICALLY
              ACKNOWLEDGE THAT THE OPTIMITY PARTIES ARE NOT LIABLE, AND YOU
              AGREE NOT TO SEEK TO HOLD THE OPTIMITY PARTIES LIABLE, FOR THE
              CONDUCT OF THIRD PARTIES, INCLUDING OTHER USERS OF THE SERVICE AND
              OPERATORS OF EXTERNAL SITES, AND THAT THE RISK OF THE SERVICE AND
              EXTERNAL SITES AND OF INJURY FROM THE FOREGOING RESTS ENTIRELY
              WITH YOU. UNDER NO CIRCUMSTANCES WILL THE OPTIMITY PARTIES BE
              LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU, OR A THIRD PARTY ON
              YOUR BEHALF, HAVE PAID OPTIMITY IN THE ONE HUNDRED AND EIGHTY DAYS
              (180) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST
              ASSERT ANY SUCH CLAIM. YOU ACKNOWLEDGE AND AGREE THAT IF YOU, OR A
              THIRD PARTY ON YOUR BEHALF, HAVE NOT PAID OPTIMITY ANY AMOUNTS IN
              THE ONE HUNDRED AND EIGHTY DAYS (180) DAYS IMMEDIATELY PRECEDING
              THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM, YOUR SOLE AND
              EXCLUSIVE REMEDY FOR ANY DISPUTE WITH OPTIMITY IS TO STOP USING
              THE SERVICE AND TO CANCEL YOUR ACCOUNT. SOME JURISDICTIONS DO NOT
              ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
              EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. THEREFORE,
              SOME OF THE ABOVE LIMITATIONS IN THE SECTION MAY NOT APPLY TO YOU.
            </p>
            <p>4.3. Indemnification</p>
            <p>
              You agree to indemnify, save, and hold Optimity, its affiliated
              companies, contractors, employees, agents and its third-party
              suppliers, licensors, and partners harmless from any claims,
              losses, damages, liabilities, including legal fees and expenses,
              arising out of your use or misuse of the Service, any violation by
              you of these Terms of Service, or any breach of the
              representations, warranties, and covenants made by you herein.
              Optimity reserves the right, at your expense, to assume the
              exclusive defense and control of any matter for which you are
              required to indemnify Optimity, and you agree to cooperate with
              Optimity's defense of these claims. Optimity will use reasonable
              efforts to notify you of any such claim, action, or proceeding
              upon becoming aware of it. You agree that the provisions in this
              paragraph will survive any termination of your Account(s) or of
              the Service.
            </p>

            <p>5. GENERAL PROVISIONS</p>
            <p>5.1. Severability</p>
            <p>
              You and Optimity agree that if any portion of these Terms of
              Service or of the OPTIMITY Privacy Policy is found illegal or
              unenforceable, in whole or in part by any court of competent
              jurisdiction, such provision shall, as to such jurisdiction, be
              ineffective solely to the extent of such determination of
              invalidity or unenforceability without affecting the validity or
              enforceability thereof in any other manner or jurisdiction and
              without affecting the remaining provisions of the Terms, which
              shall continue to be in full force and effect.
            </p>
            <p>5.2. Law and Forum for Legal Disputes</p>
            <p>
              Any dispute arising out of or related to it or the Service shall
              be governed in all respects by the laws of the Province of
              Ontario, Canada, without regard to conflict of law provisions. You
              agree that any claim or dispute you may have against Optimity must
              be resolved exclusively by a court located in the City of Toronto,
              Ontario, Canada, except as otherwise agreed by the parties or as
              described in the Arbitration Option paragraph below. You agree to
              submit to the jurisdiction of the courts located in the City of
              Toronto, Ontario, Canada for the purpose of litigating all such
              claims or disputes.
            </p>
            <p>5.3. Assignment</p>
            <p>
              Optimity may assign or delegate these Terms of Service and/or the
              Optimity Privacy Policy, in whole or in part, to any person or
              entity at any time with or without your consent. You may not
              assign or delegate any rights or obligations under the Terms of
              Service or Privacy Policy without Optimity's prior written
              consent, and any unauthorized assignment and delegation by you is
              ineffective.
            </p>
            <p>5.4. Supplemental Policies</p>
            <p>
              Optimity may publish additional policies related to specific
              services such as forums, contests or loyalty programs. Your right
              to use such services is subject to those specific policies and
              these Terms of Service.
            </p>
            <p>5.5. Entire Agreement</p>
            <p>
              These Terms of Service, any other applicable Optimity policies and
              any documents expressly incorporated by reference herein
              (including the Optimity Privacy Policy), contain the entire
              understanding of you and Optimity, and supersede all prior
              understandings of the parties hereto relating to the subject
              matter hereof, whether electronic, oral or written, or whether
              established by custom, practice, policy or precedent, between you
              and us with respect to the Service.
            </p>
            <p>5.6. No Waiver</p>
            <p>
              The failure of Optimity to require or enforce strict performance
              by you of any provision of these Terms of Service or the Optimity
              Privacy Policy or failure to exercise any right under them shall
              not be construed as a waiver or relinquishment of Optimity's right
              to assert or rely upon any such provision or right in that or any
              other instance. The express waiver by Optimity of any provision,
              condition, or requirement of these Terms of Service or the
              Optimity Privacy Policy shall not constitute a waiver of any
              future obligation to comply with such provision, condition or
              requirement. Except as expressly and specifically set forth in
              this these Terms of Service, no representations, statements,
              consents, waivers, or other acts or omissions by Optimity shall be
              deemed a modification of these Terms of Service nor legally
              binding, unless documented in physical writing, hand signed by You
              and a duly appointed officer of Optimity.
            </p>
            <p>5.7. Notices</p>
            <p>
              We may notify you via postings on www.myoptimity.com, and via e-
              mail or any other communications means to contact information you
              provide to us. All notices given by you or required from you under
              these Terms of Service or the Optimity Privacy Policy shall be in
              writing and addressed to: 111 Peter St. Suite 300 Toronto, ON, M5V
              2H1. Any notices that you provide without compliance with this
              Section on Notices shall have no legal effect.
            </p>
            <p>5.8. Equitable Remedies</p>
            <p>
              You acknowledge that the rights granted and obligations made under
              these Terms of Service to Optimity are of a unique and
              irreplaceable nature, the loss of which shall irreparably harm
              Optimity and which cannot be replaced by monetary damages alone so
              that Optimity shall be entitled to injunctive or other equitable
              relief (without the obligations of posting any bond or surety or
              proof of damages) in the event of any breach or anticipatory
              breach by you. You irrevocably waive all rights to seek injunctive
              or other equitable relief, or to enjoin or restrain the operation
              of any Service, exploitation of any advertising or other materials
              issued in connection therewith, or exploitation of the Service or
              any content or other material used or displayed through the
              Service and agree to limit your claims to claims for monetary
              damages, limited by Section 7.2 (if any).
            </p>
            <p>5.9. Force Majeure</p>
            <p>
              Optimity shall not be liable for any delay or failure to perform
              resulting from causes outside the reasonable control of Optimity,
              including without limitation any failure to perform hereunder due
              to unforeseen circumstances or cause beyond Optimity's control
              such as acts of God, war, terrorism, riots, embargoes, acts of
              civil or military authorities, fire, floods, accidents, strikes,
              or shortages of transportation facilities, fuel, energy, labor or
              materials.
            </p>
            <p>5.10. Customer Service and Technical Support</p>
            <p>
              Customer service and technical support is available via email at
              engage@myoptimity.com
            </p>
            <p>5.11. Distribution of Third Party Content</p>
            <p>
              Optimity is a distributor (and not a publisher) of content
              supplied by third parties and Users. Any opinions, advice,
              statements, services, offers, or other information or content
              expressed or made available by third parties, including
              information providers, Users are those of the respective author(s)
              or distributor(s) and not of Optimity. Neither Optimity nor any
              third-party provider of information guarantees the accuracy,
              completeness, or usefulness of any content, nor its
              merchantability or fitness for any particular purpose.
            </p>
            <p>5.12. Extension of Benefits</p>
            <p>
              Customer agrees that the rights and benefits of Optimity pursuant
              to Sections 1.2 and 5 as well as any indemnity, any limitation of
              liability and any consent granted by the Customer to Optimity
              hereunder shall also extend to your employer, if your employer
              pays for your access to this Service which is, solely for the
              purposes set out in this Section 5.12 and the Sections of this
              Agreement referred to in this Section 5.12, a party to this
              Agreement.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}
