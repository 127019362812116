import "./Terms.css";

import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

export default class Privacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  render() {
    return (
      <div className="Terms container">
        <Row>
          <Col sm={12}>
            <h1 className="text-center">Privacy Policy</h1>
            <p>Updated: March 27, 2020</p> 
            <p>
              OPTIMITY Inc. wants to ensure that you are aware how we collect
              and use information you supply to us when using our service. By
              accepting our Privacy Policy and Terms of Service in registering
              to use our service, you consent to our collection, storage, use
              and disclosure of your personal information as described in this
              Privacy Policy.
            </p>
            <p>
              If you have questions or complaints regarding our privacy policy
              or practices, please contact us as described in the section titled
              ‘Contact Us’.
            </p>{" "}
             <p>INTRODUCTION</p>
            <p>
              OPTIMITY’s FINWELL Service (the “Service”) provides general
              information. It is not meant to be taken as legal, accounting,
              financial, insurance, borrowing or tax advice, and it is not meant
              as an offer to sell you anything. You must create your own
              financial plan and talk with an expert in order to receive advice
              on your personal situation. In this policy, "OPTIMITY" refers to
              OPTIMITY Inc. and its Affiliates, which shall mean subsidiaries,
              parent companies, joint ventures and other corporate entities
              under common ownership. We may also refer to OPTIMITY as "We" or
              "Us".
            </p>
            <p>This Privacy Policy describes:</p>
            <p>
              • The information We collect, how We do so and the purposes of our
              collection
            </p>
            <p>• How We use and with whom We share such information</p>
            <p>• How you can access and update such information</p>
            <p>
              • The choices you can make about how We collect, use and share
              your information
            </p>
            <p>• How We protect the information We store about you</p>
            <p>
              If you access our Services from a third party site, you may be
              required to also read and accept the third party’s terms of
              service and privacy policy.
            </p>
             <p>INFORMATION WE COLLECT</p>
            <p>Information We Receive About You</p>
            <p>
              OPTIMITY may request and store information used to personalize the
              user experience as allowed by you and your preferences, such as,
              but not limited to:
            </p>
            <p>• your first and last name</p>
            <p>• email address</p>
            <p>• mobile phone number.</p>
            <p> • annual income</p>
            <p> • your physical location and that of your access devices</p>
            <p> • your gender</p>
            <p> • your age</p>
            <p> • your investments</p>
            <p>
              {" "}
              By using the Service you are authorizing OPTIMITY to collect,
              store, and use in accordance with this Privacy Policy any and all
              information that you agreed the third party could provide to
              OPTIMITY. Your agreement takes place when you "accept" or "allow"
              (or similar terms) one of our applications.
            </p>
            <p>Information We Collect from You Directly</p>
            <p>
              When you interact with the Service (whether or not on a third
              party site) We may collect and store information from you directly
              as described below:
            </p>
            <p>
              Information We Receive or Collect When You Access OPTIMITY
              Services on a Mobile Device
            </p>
            <p>
              If you use OPTIMITY Services on your mobile telephone or other
              mobile device, including iPads and tablets, We collect your mobile
              device identifier and IP Address. In certain Services We will
              create and assign to your device an identifier that is similar to
              an account number. We may collect the name you have associated
              with your device, device type, country, and any other information
              you choose to provide, such as user name, character name or e-mail
              address.
            </p>
            <p>Technical and Usage Information</p>
            <p>
              When you access our websites or use our Services, We collect (i)
              certain technical information about your mobile device or computer
              system, including IP Address and mobile device ID; and (ii) usage
              statistics about your interactions with the Service. This
              information is typically collected through the use of server log
              files or web log files (“Log Files”), mobile device software
              development kits and tracking technologies like browser cookies to
              collect and analyze certain types of technical information. Some
              of the cookies the Service places on your computer are linked to
              your user ID number(s). For more information on how We utilize
              cookies and other tracking technologies please review the "Cookies
              and Automated Information Collection" portion of Section 3 ("How
              We Collect Information about You").
            </p>
             <p>HOW WE COLLECT INFORMATION ABOUT YOU</p>
            <p>
              We may collect information about you in any one or more of the
              following ways:
            </p>
            <p>Information about Users and the Services they use</p>
            <p>
              When you use one of our Services, OPTIMITY collects and stores
              certain information that you provide directly or through the third
              party website that is offering our Service(s). To provide a richer
              experience, We also collect information about your interaction
              with the Service. The bulk of this information is collected and
              stored through the use of Log Files, which are files on our web
              servers that record actions taken on our Services and websites.
            </p>
            <p>Cookies and Automated Information Collection</p>
            <p>
              When you access the Service, We collect certain technical
              information in order to (i) analyze the usage of our sites and
              services; (ii) provide a more personalized experience; and (iii)
              manage testimonials.
            </p>
            <p>
              You can set your web browser to warn you about attempts to place
              cookies on your computer or limit the type of cookies you allow.
            </p>
            <p>Other Sources</p>
            <p>
              We may collect or receive information from other sources including
              third party information providers. This information will be used
              to supplement your profile - primarily to help you and your
              friends connect. It will be combined with other information We
              collect.
            </p>
             <p>HOW WE USE THE INFORMATION WE COLLECT</p>
            <p>The Company may use Personal Data for the following purposes:</p>
            <p>
              To provide and maintain our Service, including to monitor the
              usage of our Service.
            </p>
            <p>
              For the performance of a contract: the development, compliance and
              undertaking of the purchase contract for the products, items or
              services You have purchased or of any other contract with Us
              through the Service.
            </p>
            <p>
              To contact You: To contact You by email, telephone calls, SMS, or
              other equivalent forms of electronic communication, such as a
              mobile application’s push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </p>
            <p>
              To provide You with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless You have opted not to receive such information.
            </p>
            <p>SHARING OF YOUR INFORMATION</p>
            <p>
              We will share your information (in some cases personal
              information) with third parties, that is parties other than
              OPTIMITY, in the following circumstances:
            </p>
            <p>Third Party Service Providers</p>
            <p>
              We will provide your information to third party companies to
              perform services on our behalf, including payment processing, data
              analysis, e-mail delivery, hosting services, customer service and
              to assist Us in our marketing efforts. We direct all such third
              party service providers to maintain the confidentiality of the
              information disclosed to them and to not use your information for
              any purpose other than to provide services on OPTIMITY's behalf.
            </p>
            <p>Third Party Financial Institutions and Advisors </p>
            <p>
              We will provide information for aggregate reporting to third party
              companies who have purchased FINWELL Services, including aggregate
              income, investment, age, gender, etc. Our collection, use, and
              disclosure of anonymous or aggregated information are not subject
              to any of the restrictions in this Privacy Policy.
            </p>
            <p>
              We will provide personal information to financial institutions and
              advisors when user explicitly agrees to share personal data
              provided to Optimity through the Service and related connected
              devices for the sole purpose of the financial institution or
              financial advisor to provide valuable personalized advice and
              products, based on the information collected.
            </p>
            <p>SAFETY, SECURITY AND COMPLIANCE WITH LAW</p>
            <p>
              Your information, and the contents of all of your online
              communications (including without limitation chat text, voice
              communications, IP addresses and your personal information) may be
              accessed and monitored as necessary to provide the Service and may
              be disclosed: (i) when We have a good faith belief that We are
              required to disclose the information in response to legal process
              (for example, a court order, search warrant or subpoena); (ii) to
              satisfy any applicable laws or regulations (iii) where We believe
              that the Service is being used in the commission of a crime,
              including to report such criminal activity or to exchange
              information with other companies and organizations for the
              purposes of fraud protection and credit risk reduction; (iv) when
              We have a good faith belief that there is an emergency that poses
              a threat to the health and/or safety of you, another person or the
              public generally; and (v) in order to protect the rights or
              property of OPTIMITY, including to enforce our Terms of Service.
            </p>
            <p>SALE OR MERGER</p>
            <p>
              In the event that OPTIMITY undergoes a business transition, such
              as a merger, acquisition by another company, or sale of all or a
              portion of its assets, We may transfer all of your information,
              including personal information, to the successor organization in
              such transition. If material changes to OPTIMITY's privacy
              practices will occur as a result of the business transition, We
              will notify you and other users (in the manner described in
              Section 12) of the business transition prior to transferring your
              personal information.
            </p>
            <p>OUR POLICIES CONCERNING CHILDREN</p>
            <p>
              Our Services are not intended for children under the age of 13 and
              We do not knowingly collect any personal information from such
              children. Children under the age of 13 should not use our Services
              at any time. In the event that We learn that We have inadvertently
              gathered personal information from children under the age of 13,
              We will take reasonable measures to promptly erase such
              information from our records.
            </p>
            <p>HOW TO ACCESS AND UPDATE YOUR INFORMATION</p>
            <p>
              Information We Receive From a third party site when You use Our
              Service
            </p>
            <p>
              To manage the information OPTIMITY receives about you from a third
              party site where you use our Services, you will need to follow the
              instructions at that site for updating your information and
              changing your privacy settings. You can manage certain aspects of
              information collection and use by going to the settings of your
              (mobile) device and reviewing the permissions of each application.
            </p>
            <p>
              Once OPTIMITY receives your information from a third party site,
              that information is stored and used by OPTIMITY in accordance with
              this Privacy Policy, and you may access and update that
              information as described below. User accounts created with
              OPTIMITY are considered active until We receive a user request to
              delete them or deactivate them.
            </p>
            <p>Other Methods of Accessing and Controlling your Information</p>
            <p>
              If you no longer want OPTIMITY to make active use of your
              information, you may send an e-mail to support@myoptimity.com.
              Place "Delete My Account" in the subject line and include your
              first name, last name, e-mail address and user ID number for the
              third party site from which you access our Services (if
              applicable) in the body of the e-mail. We will respond to your
              request within thirty (30) days. Please note that certain records,
              for example those pertaining to payments or customer service
              matters, will be retained for legal and accounting purposes. If
              you have sent or posted content on the Service, We may not be able
              to delete it.
            </p>
            <p>
              If you wish to review or change the information OPTIMITY has about
              you, e-mail Us at the address provided herein.
            </p>
            <p>
              If you have additional questions about this Privacy Policy,
              contact Us at support@myoptimity.com.
            </p>
            <p>YOUR SHARING AND MESSAGING OPTIONS</p>
            <p>
              Information We Receive from Third Party Sites Where You use a
              OPTIMITY Service
            </p>
            <p>
              If you use our Services at a third-party site, OPTIMITY will be
              able to access and store profile and other information about you
              as described above. The categories of information you share with
              Us will depend, in part, on the permissions you grant Us and the
              privacy settings you have established at the third party site
              where you access the application. OPTIMITY does not offer an "opt
              out" option for specific fields that are shared by the third party
              site where you access and use the Service(s).
            </p>
            <p>Opting Out of Other Communications</p>
            <p>
              When you install our apps on your mobile device you can choose to
              receive push notifications. You can turn off push notifications by
              visiting the “options” or “settings” page within the relevant
              Service. You may also receive local notifications. You can turn
              off local notifications by visiting the “options” or “settings”
              page within the relevant Service.
            </p>
            <p>
              We may e-mail or communicate with you from time to time if We need
              to provide you with information or if We need to request
              information from you with respect to a transaction initiated by
              you or for other legitimate non-marketing reasons.
            </p>
            <p>SECURITY OF YOUR INFORMATION</p>
            <p>
              OPTIMITY implements reasonable security measures to protect the
              security of your information both online and offline, and We are
              committed to the protection of customer information. In the event
              that We are aware of a breach of information We will inform you
              and your employer of the breach.
            </p>
            <p>
              For user accounts registered directly with OPTIMITY profile
              information is protected by the password each member uses to
              access their online account. It is important that you protect and
              maintain the security of your account and that you immediately
              notify Us of any unauthorized use of your account. If you forget
              the password to your OPTIMITY account, the website allows you to
              request that instructions be sent to you that explain how to reset
              your password. When you sign into your account or enter payment
              information (such as a credit card number when purchasing virtual
              currency), We encrypt the transmission of that information using
              secure socket layer technology ("SSL").
            </p>
            <p>
              While We take reasonable precautions against possible security
              breaches of our websites and our customer databases and records,
              no website or Internet transmission is completely secure, and We
              cannot guarantee that unauthorized access, hacking, data loss, or
              other breaches will never occur. We urge you to take steps to keep
              your personal information safe (including your account password),
              and to log out of your account after use. If your third party site
              account is hacked, this may lead to unauthorized use of OPTIMITY
              Service you have registered to use, so be careful to keep your
              account information secure. If you have questions about the
              security of our websites, please contact Us
              at support@myoptimity.com.
            </p>
            <p>
              Unfortunately, the transmission of information over the Internet
              is not completely secure. Although We strive to protect your
              personal data, We cannot guarantee the security of your data while
              it is being transmitted to our site; any transmission is at your
              own risk. Once We have received your information, We have
              procedures and security features in place to try to prevent
              unauthorized access.
            </p>
            <p>FOREIGN USE OF THE SERVICE</p>
            <p>
              To the extent that you are accessing the Service while domiciled
              outside of the United States, you acknowledge that the Personal
              Information you are providing Us is collected and stored in the
              United States and therefore consent to the transfer of information
              to and storage of the information outside of your domiciled
              country and in the United States.
            </p>
            <p>CALIFORNIA USER CONSUMER RIGHTS</p>
            <p>
              In accordance with California Civil Code Sec. 1789.3, California
              resident users are entitled to know that they may file grievances
              and complaints with California Department of Consumer Affairs, 400
              R Street, STE 1080, Sacramento, CA 95814; or by phone at
              916-445-1254 or 800-952-5210; or by email to dca@dca.ca.gov. For
              more information about protecting your privacy, you may wish to
              visit: http://www.ftc.gov. If you have questions about this
              policy, please contact us at support@myoptimity.com.
            </p>
            <p>CHANGES TO OUR PRIVACY POLICY </p>
            <p>
              If We decide to make material changes to our Privacy Policy, We
              will notify you and other users by placing a notice on
              myOptimity.com or by sending you a notice to the e-mail address We
              have on file for you. We may supplement this process by placing
              notices in our Services. You should periodically check
              myOptimity.com and this privacy page for updates.
            </p>
            <p>RIGHT TO ACCESS</p>
            <p>
              Users in certain jurisdictions have a right to access personal
              information held about themselves. Your right of access can be
              exercised in accordance with applicable law. Please submit any
              requests for access to your personal data in writing
              to support@myoptimity.com.
            </p>
            <p>CONTACT US</p>
            <p>
              If you have any questions, comments or concerns regarding our
              Privacy Policy and/or practices, please send an e-mail
              to support@myoptimity.com. All other inquiries should be directed
              to OPTIMITY's Feedback Page or support@myoptimity.com.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}
