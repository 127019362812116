const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "fin-api-dev-attachmentsbucket-16vzzkliwhtux"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://tnqk4tybm0.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_VAFeEkyah",
    APP_CLIENT_ID: "48tki724te9cmq450ceits24re",
    IDENTITY_POOL_ID: "us-east-1:2a1dff1f-b5ad-4ef6-99b0-3fb0d16f9657"
  }
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "fin-api-prod-attachmentsbucket-1ersr452y26ty"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://9b73ls7e60.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_hBAVvnthK",
    APP_CLIENT_ID: "2ncc5qglmbsuap91q9b8ifup08",
    IDENTITY_POOL_ID: "us-east-1:0a70fde3-6fc6-403d-8f1c-ca8eaa590eaa"
  }
};

// Default to dev if not set
const config = process.env.NODE_ENV === 'production'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};