import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { Link } from "react-router-dom";
import "./Login.css";

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      props.userHasAuthenticated(true);
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (
    <div className="Login container">
        <Row>
          <Col sm={8} smOffset={2}>
            <h1 className='text-center'>Log In</h1>
            <h3 className='text-center'>Build and Optimize Conversation Funnels on LinkedIn.</h3>
            <br />
            <div className='form-wrapper'>
              <form onSubmit={handleSubmit}>
                <FormGroup controlId="email" bsSize="large">
                  <ControlLabel>Email</ControlLabel>
                  <FormControl
                    autoFocus
                    type="email"
                    value={fields.email}
                    onChange={handleFieldChange}
                  />
                </FormGroup>
                <FormGroup controlId="password" bsSize="large">
                  <ControlLabel>Password</ControlLabel>
                  <FormControl
                    type="password"
                    value={fields.password}
                    onChange={handleFieldChange}
                  />
                </FormGroup>
                <Link to="/login/reset">Forgot password?</Link>
                <LoaderButton
                  className='btn-primary'
                  type="submit"
                  bsSize="large"
                  isLoading={isLoading}
                  disabled={!validateForm()}
                >
                  Login
                </LoaderButton>
              </form>
          </div>
          </Col>
        </Row>
    </div>
  );
}