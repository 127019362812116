import "./App.css";

import { Link, withRouter } from "react-router-dom";
import React, { useState } from "react";

import { Navbar } from "react-bootstrap";
import Routes from "./Routes";

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  return (
    <div className="App">
      <div className="">
        <Navbar collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <img
                  alt="finwell-logo"
                  height="60"
                  src="https://conversify-marketing.s3.amazonaws.com/finWell-logo.png"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
        </Navbar>
        <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
        <div className="footer">
          <div className="copy">
            <div className="copy-inner">
              <span className="fadedspan">
                <a href="/terms">Terms of Service</a> |{" "}
                <a href="/privacy">Privacy Policy</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(App);
