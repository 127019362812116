import React from "react";
import "./Home.css";

export default function Home(props) {

  function renderLander() {
    return (
      <div className="lander container">
          <h1 className='text-center'>Retirement Calculator</h1>
          <p className='text-center'>Calculate your retirement age for free in about two minutes.</p>
          <br /><br />
          <p className='text-center'><a className='btn-primary' href="/r/home">Get started</a></p>
          <br /><br />
      </div>
    );
  }

  return (
    <div className="Home">
      {renderLander()}
    </div>
  );
}