import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Drift from "react-driftjs";
import Loading from "./Loading";

export default function ViewProfile(props) {
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [restricted, setRestricted] = useState(false);
  const [stage, setStage] = useState("retirementAge");

  useEffect(() => {
    function loadProfile() {
      return API.get("profiles", `/profiles/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const profile = await loadProfile();

        setProfile(profile);
        setIsLoading(true);
      } catch (e) {
        setRestricted(true);
        setIsLoading(true);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function retirementAgeExperience() {
    return (
      <div>
        <br />
        <h1>
          Hi {profile.firstName}, we think you can retire around{" "}
          {profile.predictedAge}
        </h1>
        <br />
        <br />
        <p>
          <span
            onClick={() => setStage("retirementSpending")}
            className="btn-primary"
          >
            See how
          </span>
        </p>
        <br />
        <br />
        <br />
      </div>
    );
  }

  function retirementSpendingExperience() {
    return (
      <div>
        <br />
        <h1>We assume you'll spend about $43,000 per year in retirement</h1>
        <br />
        <br />
        <p>
          <span
            onClick={() => setStage("retirementInvesting")}
            className="btn-primary"
          >
            Next
          </span>
        </p>
        <br />
        <br />
        <br />
      </div>
    );
  }

  function retirementInvestingExperience() {
    return (
      <div>
        <br />
        <h1>
          We will show you how to you leverage your TFSA and RRSP to maximize
          your tax efficiency (this will save you tens of thousands of dollars).
        </h1>
        <br />
        <br />
        <p>
          <span
            onClick={() => setStage("retirementHealth")}
            className="btn-primary"
          >
            Next
          </span>
        </p>
        <br />
        <br />
        <br />
      </div>
    );
  }

  function retirementHealthExperience() {
    return (
      <div>
        <br />
        <h1>
          We will show you how to protect yourself from any significant health
          problems that could threaten your retirement.
        </h1>
        <br />
        <br />
        <p>
          <span onClick={() => setStage("bookCall")} className="btn-primary">
            Next
          </span>
        </p>
        <br />
        <br />
        <br />
      </div>
    );
  }

  function bookCallExperience() {
    return (
      <div>
        <br />
        <h1>
          We have a live Financial Guru who is now going to answer your
          additional questions through chat.
        </h1>
        <br />
        <br />
        <p>
          <span
            onClick={() => window.drift.api.openChat()}
            className="btn-primary"
          >
            Yes please!
          </span>
        </p>
        <br />
        <br />
        <br />
      </div>
    );
  }

  function thankYouExperience() {
    switch (stage) {
      case "retirementSpending":
        return retirementSpendingExperience();
      case "retirementInvesting":
        return retirementInvestingExperience();
      case "retirementHealth":
        return retirementHealthExperience();
      case "bookCall":
        return bookCallExperience();
      default:
        return retirementAgeExperience();
    }
  }

  return (
    <div className="ViewProfile container">
      {isLoading ? (
        restricted ? (
          <div className="Thanks container">
            <Row>
              <Col sm={6} smOffset={3} className="text-center">
                <br />
                <h1>Restricted!</h1>
                <br />
                <p>
                  You can't view someone else's results. Use our calculator to
                  find out when you can retire in less than 2 minutes.
                </p>
                <br />
                <br />
                <p>
                  <a className="btn-primary" href="/r/restricted">
                    Retirement Calculator
                  </a>
                </p>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="Thanks container">
            <Row>
              <Col sm={8} smOffset={2} className="text-center">
                {thankYouExperience()}
              </Col>
            </Row>
            <Drift
              appId="9g8xza3hh5yu"
              userId={profile.profileId}
              attributes={profile}
            />
          </div>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
}
